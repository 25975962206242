<template>
    
    <v-dialog
        v-model="dialog"
        width="500"
    >

        <template v-slot:activator="{ on, attrs }">

            <v-btn
                :color="app.state.style.primary"
                dark depressed :block="block"
                v-bind="attrs"
                v-on="on"
            >
                {{ $t('Add') }}
            </v-btn>

        </template>
        

        <v-card>

            <v-card-title>
                {{ $t('Add') }}
            </v-card-title>

            <v-divider></v-divider>

            <div class="pa-4">

                <v-text-field
                    :label="$t('Name')"
                    v-model="pageName"
                    outlined hide-details
                    @change="setSlug()"
                ></v-text-field>
            
            </div>

            <v-divider></v-divider>

            <div class="pa-4">

                <v-btn
                    :color="app.state.style.primary"
                    :disabled="pageName == ''"
                    depressed @click="add()"
                    v-if="pageName == ''"
                    :loading="loading"
                >
                    {{ $t('Add') }}
                </v-btn>

                <v-btn
                    :color="app.state.style.primary"
                    dark
                    depressed @click="add()"
                    :loading="loading"
                    v-else
                >
                    {{ $t('Add') }}
                </v-btn>

                <v-btn
                    :color="app.state.style.primary"
                    outlined
                    class="ml-2"
                    @click="dialog=false"
                >
                    {{ $t('Cancel') }}
                </v-btn>

            </div>

        </v-card>

    </v-dialog>
    
</template>

<script>
    
import { inject, ref } from '@vue/composition-api' ;
import pages from "@/store/pages/pages" ;
import firebase from 'firebase' ;

export default {
    
    props: ['block', 'parent'],

    provide: {
        pages
    },

    setup(props, ctx){
    
        const app = inject('app') ;
        const currentWebsite = inject('currentWebsite') ;

        const dialog = ref(false) ;
        const pageName = ref('') ;
        const pageSlug = ref(null) ;
        const loading = ref(false) ;

        const setSlug = async () => {

            loading.value = true ;

            let slug = await pages.getFreeSlug(pageName.value) ;

            console.debug('setSlug', slug);

            if(slug){
                pageSlug.value = slug ;
            }

            loading.value = false ;
            
        }

        const add = () => {

            loading.value = true ;

            if(pageSlug.value == null || pageSlug.value == ''){
                app.setError('Please add a valid slug');
                return ;
            }

            if(pageName.value == null || pageName.value == ''){
                app.setError('Please add a valid name');
                return ;
            }

            var data = {
                language: pages.state.selectedLanguage,
                type: pages.state.selectedType,
                status: 'hidden',
                name: pageName.value,
                slug: pageSlug.value,
                parent: null,
                parent_id: null,
                created: new Date(),
                lastModified: new Date(),
                path: "/"+pageSlug.value,
            }

            if(props.parent?.id != null){
                data.parent_id = props.parent.id ;
                data.parent = firebase.firestore().collection("websites").doc(currentWebsite.id()).collection("pages").doc(props.parent.id) ;
                data.path = "/"+props.parent.data().slug+"/"+pageSlug.value ;
            }

            pages.add(data)
            .then((result) => {
                console.debug("pages add ", result) ;
                ctx.emit('added', result) ;
                app.setSuccess(ctx.root.$t('Page created')) ;
                ctx.root.$router.push('/pages/'+result.id+'?action=new') ;
            }).catch((error) => {
                console.error("Error adding document: ", error);
                app.setError(error) ;
            });

        } ;

        return {
            app,
            dialog,
            pageName,
            setSlug,
            pageSlug,
            add,
            loading
        }
    
    },

};

</script>
<template>
    
    <div class="pa-4 pr-2 pt-0">

        <div class="ml-15 pt-4 pb-2" v-if="loading">

            <v-progress-linear
            indeterminate
            :color="app.state.style.primary"
            ></v-progress-linear>

        </div>

        <div 
        v-for="page in pagesData"
        :key="page.id"
        class="d-flex align-center">

            <v-icon class="mr-5 ml-4">mdi-arrow-right-bottom</v-icon>

            <v-card
            @click="selectPage(page)"
            class="pa-4 d-flex align-center flex-grow-1" tile>

                {{ page.data().name }}

                <v-spacer></v-spacer>

                <v-avatar
                    size="20"
                    :color="getPageColor(page.data())"
                    class="mr-4"
                >
                </v-avatar>

                <img width="30px" height="30px" contain :src="app.getLanguageImage(page.data().language)" />

                <v-icon right>mdi-chevron-right</v-icon>

            </v-card>

        </div>

        <!-- {{ parent }} -->

        <div class="d-flex align-center py-4">

            <v-icon class="mr-5 ml-4">mdi-plus</v-icon>

            <AddPage v-if="showAddPage" @added="get()" :parent="parent" />

        </div>
            
    </div>
    
</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;
import pages from "@/store/pages/pages" ;
import AddPage from "@/components/pages/Add" ;

export default {
    
    props: ['parent', 'showAddPage'],
    components: {
        AddPage
    },

    provide: {
        pages
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;

        const pagesData = ref([]) ;
        const loading = ref(false) ;

        const getPageColor = (page) => {

            if(page.status == 'hidden'){
                return 'red' ;
            }

            if(page.status == 'public'){
                return 'green' ;
            }

            return 'grey' ;
            
        } ;

        const get = async () => {

            pagesData.value = [] ;
            loading.value = true ; 

            let args = {
                language: pages.state.selectedLanguage,
                parent_id: props.parent.id,
                type: pages.state.selectedType,
            } ;

            if(pages.state.selectedStatus != null){
                args.status = pages.state.selectedStatus ;
            }
            
            pages.get(args)
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {
                    pagesData.value.push(doc);
                });

                loading.value = false ; 

            }).catch((error) => {

                console.log("Error getting documents: ", error);
                app.setError(error) ;

                loading.value = false ; 

            });

        } ;

        onMounted(() => {
            get() ;
        });

        const selectPage = (page) => {
            ctx.emit('selected', page);        
        } ;

        return {
            app,
            currentWebsite,
            currentUser,
            pagesData,
            getPageColor,
            loading,
            selectPage
        }
    
    },

};

</script>
import { reactive } from '@vue/composition-api' ;
import firebase from 'firebase' ;
import currentWebsite from "@/store/websites/currentWebsite" ;
// import app from "@/store/app" ;

const state = reactive({
    selectedLanguage: null,
    selectedStatus: null,
    selectedType: 'page',
});

const get = (args = null) => {

    let query = firebase.firestore().collection("websites").doc(currentWebsite.id()).collection("pages")

    if(args?.language != null){
        query = query.where('language', '==', args.language) ;
    }

    if(args?.status != null){
        query = query.where('status', '==', args.status) ;
    }

    if(args?.type != null){
        query = query.where('type', '==', args.type) ;
    }

    if(args?.parent_id != null){
        query = query.where('parent_id', '==', args.parent_id) ;
    }else{
        query = query.where('parent_id', '==', null) ;
    }
    
    return query.get() ;

}


const add = (data = {}) => {

    return firebase.firestore()
    .collection("websites").doc(currentWebsite.id())
    .collection("pages").add(data) ;
    
}

const getFreeSlug = async (name, number = 0) => {

    let slug = null ; 

    if(number == 0){
        slug = getSlug(name) ; 
    }else{
        slug = getSlug(name)+"-"+number ; 
    }

    // console.debug('pageSlug slug', slug) ;

    let query = firebase.firestore().collection("websites").doc(currentWebsite.id()).collection("pages") ;
    query = query.where("slug", "==", slug) ;

    try {
        var querySnapshot = await query.get();
        
        if(querySnapshot.empty){
            return slug ;
        }else{
            return getFreeSlug(name, number + 1) ; 
        }

    }
    catch (error) {
        console.error("Error slug documents: ", error);
        return false ;
    }

}

function getSlug(slug) {

    return slug.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

}

const getParentPage = async (pageId) => {

    try {
        
        return await firebase.firestore().collection("websites")
        .doc(String(currentWebsite.id()))
        .collection("pages")
        .doc(String(pageId))
        .get() ;
        
    } catch (error) {
        return error ;
    }


}

const getUri = async (page) => {

    let parentUri = ""; 

    if(page.data().parent_id != null){

        let parentPage = await getParentPage(page.data().parent_id);
        
        // console.debug('parentPage', parentPage.data().slug);

        parentUri = "/"+parentPage.data().slug ;

    }

    console.debug('parentUri', parentUri+"/"+page.data().slug);

    return parentUri+"/"+page.data().slug ;

}

export default {
    state, 
    add,
    get,
    getFreeSlug,
    getUri
 } ;
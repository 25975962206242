<template>
    
    <div>

        <v-card class="pa-4 ma-2 d-flex align-center">
            
            <v-icon left>mdi-filter-variant</v-icon>

            <v-spacer></v-spacer>
            
            <v-select
                :items="types"
                :label="$t('Select type')"
                outlined hide-details
                dense style="max-width:200px;" class="mr-2"
                v-model="pages.state.selectedType"
                @change="get()"
                v-if="showTypes"
            ></v-select>

            <v-select
                :items="statusItems"
                :label="$t('Select status')"
                outlined hide-details
                dense style="max-width:200px;"
                v-model="pages.state.selectedStatus"
                @change="get()"
            ></v-select>

            <v-select
                :items="app.state.availableLanguages"
                :label="$t('Select language')"
                outlined hide-details
                dense style="max-width:200px;"
                class="ml-2"
                v-model="pages.state.selectedLanguage"
                item-value="key"
                item-text="name"
                @change="get()"
            ></v-select>

        </v-card>

        <div class="pa-4" v-if="loading">

            <v-progress-linear
            indeterminate
            :color="app.state.style.primary"
            ></v-progress-linear>

        </div>

        <div 
        v-for="page in pagesData"
        :key="page.id"
        v-else>

            <v-card  class="ma-2 d-flex align-center">


                <div class="px-4">
                    
                    <v-btn @click="selectedParentPage = null" icon v-if="selectedParentPage == page.id">
                        <v-icon >mdi-folder-open-outline</v-icon>
                    </v-btn>

                    <v-btn @click="selectedParentPage = page.id" icon v-else>
                        <v-icon>mdi-folder-outline</v-icon>
                    </v-btn>

                </div>

                <v-divider vertical class="mr-0"></v-divider>

                <v-card
                @click="selectPage(page)"
                class="pa-4 d-flex align-center flex-grow-1"
                elevation="0">

                    {{ page.data().name }}

                    <v-spacer></v-spacer>

                    <v-avatar
                        size="20"
                        :color="getPageColor(page.data())"
                        class="mr-4"
                    >
                    </v-avatar>

                    <img width="30px" height="30px" contain :src="app.getLanguageImage(page.data().language)" />

                    <v-icon right>mdi-chevron-right</v-icon>

                </v-card>
                
            </v-card>

            <SubList @selected="selectPage" :show-add-page="showAddPage" :parent="page" v-if="selectedParentPage == page.id" />
            
        </div>

        <div class="pa-2">

            <AddPage v-if="showAddPage" @added="get()" :block="true" />

        </div>
        
    </div>
    
</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;
import pages from "@/store/pages/pages" ;
import SubList from "@/components/pages/SubList" ;
import AddPage from "@/components/pages/Add" ;

export default {
    props: ['showAddPage', 'type', 'showTypes'],
    components: {
        SubList,
        AddPage
    },
    provide: {
        pages
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;

        const pagesData = ref([]) ;
        const selectedParentPage = ref(null) ;
        const loading = ref(false) ;

        const statusItems = [
            {
                value: null,
                text: ctx.root.$t('All')
            },
            {
                value: 'hidden',
                text: ctx.root.$t('Hidden')
            },
            {
                value: 'public',
                text: ctx.root.$t('Public')
            },
        ] ;

        const types = [
            {
                value: 'page',
                text: ctx.root.$t('Pages')
            },
            {
                value: 'news',
                text: ctx.root.$t('News')
            },
            {
                value: 'accommodation',
                text: ctx.root.$t('Accommodation')
            }
        ] ;

        if(pages.state.selectedLanguage == null){
            pages.state.selectedLanguage = currentWebsite.state.data.primary_language ;
        }

        const getPageColor = (page) => {

            if(page.status == 'hidden'){
                return 'red' ;
            }

            if(page.status == 'public'){
                return 'green' ;
            }

            return 'grey' ;
            
        } ;

        const get = async () => {

            pagesData.value = [] ;
            loading.value = true ;

            var args = {
                language: pages.state.selectedLanguage,
                type: pages.state.selectedType,
            } ;

            if(pages.state.selectedStatus != null){
                args.status = pages.state.selectedStatus ;
            }
            
            pages.get(args)
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {
                    pagesData.value.push(doc);
                });

                loading.value = false ;

            }).catch((error) => {

                console.log("Error getting documents: ", error);
                app.setError(error) ;

                loading.value = false ;

            });

        } ;

        onMounted(() => {

            if(props.type != null){
                pages.state.selectedType = props.type ;
            }

            get() ;
            
        });

        const selectPage = (page) => {
            ctx.emit('selected', page);            
        } ;

        return {
            app,
            currentWebsite,
            currentUser,
            pagesData,
            getPageColor,
            selectedParentPage,
            get,
            pages,
            statusItems,
            loading,
            selectPage,
            types
        }
    
    },

};

</script>
<template>
    
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

        <template v-slot:activator="{ on, attrs }">

            <div
            v-bind="attrs"
            >

                <v-card outlined v-if="selectedPage" class="pa-4 d-flex align-center">

                    <div>{{ selectedPage.name }}</div>

                    <v-spacer></v-spacer>
                    
                    <v-btn icon @click="selectedPage = null">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>

                </v-card>

                <v-btn
                    :color="app.state.style.primary"
                    outlined v-on="on"
                    v-else
                >
                    {{ $t('Select page') }}
                </v-btn>
                
            </div>

        </template>
        
        <v-card>

            <v-card-title>
                {{ $t('Select page') }}
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <div class="pa-4">
                <PagesList @selected="selectPage" :show-types="true"/>
            </div>

        </v-card>

    </v-dialog>

</template>

<script>
    
import { inject, ref } from '@vue/composition-api' ;
import PagesList from "@/components/pages/List" ;

export default {
    
    components: {
        PagesList
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const dialog = ref(false) ;
        const selectedPage = ref(null) ;

        const selectPage = (page) => {

            let tmp = page.data() ;
            tmp.id = page.id ;

            selectedPage.value = tmp ;
            dialog.value = false ;

            ctx.emit('selected', page) ;

        }

        return {
            app,
            dialog,
            selectPage,
            selectedPage
        }
    
    },

};

</script>